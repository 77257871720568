/**
  * Set a cookie with options.
  * @param {string} name - The name of the cookie.
  * @param {string} value - The value of the cookie.
  * @param {object} options - Options for the cookie.
  */
function setCookie(name, value, options) {
    let cookieString = `${name}=${encodeURIComponent(value)}`;

    if (options.path) cookieString += `; path=${options.path}`;
    if (options.domain) cookieString += `; domain=${options.domain}`;
    if (options.secure) cookieString += `; secure`;
    if (options.sameSite) cookieString += `; samesite=${options.sameSite}`;

    document.cookie = cookieString;
}

/**
 * Get the value of a cookie by name.
 * @param {string} name - The name of the cookie.
 * @returns {string|null} The cookie value or null if not found.
 */
function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
        const [key, value] = cookie.split('=');
        if (key.trim() === name) {
            return decodeURIComponent(value);
        }
    }
    return null;
}

function removeCookie(name, options = {}) {
    this.setCookie(name, '', { ...options, expires: -1 });
}

module.exports = {
    setCookie: setCookie,
    getCookie: getCookie,
    removeCookie: removeCookie
};