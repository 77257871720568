var cookieHelpers = require('../helpers/cookieHelpers');

const CONVERT_GOALS = {
    MP_INTRODUCTION: '100426494',
    MP_HEALTH: '100426495',
    MP_NUTRITION_AND_ALLERGENS: '100426496',
    MP_PLAN_SELECTION: '100426497',
    MP_SUMMARY: '100426498',
    MP_ACCOUT_CREATION: '100461515',
    MP_LOGIN: '100478209',
    MP_CHECKOUT: '100455947',
    MP_CHECKOUT_PAYMENT: '100460335',
    MP_CHECKOUT_CONFIRMATION: '100426485',
    DUALMODE_CART: '100482873',
    DUALMODE_INTENT_PROCEED: '100482875',
    DUALMODE_CHECKOUT: '100482876',
    DUALMODE_CHECKOUT_PAYMENT: '100482877',
    DUALMODE_CHECKOUT_CONFIRMATION: '100482878',
};

const STATSIG_GOALS = {
    DUALMODE_CART: { name: 'ecomm_cart', value: null },
    DUALMODE_INTENT_PROCEED: { name: 'ecomm_step_continue_cta', value: 'cart' },
    DUALMODE_CHECKOUT: { name: 'ecomm_checkout', value: null },
    DUALMODE_CHECKOUT_PAYMENT: { name: 'ecomm_payment', value: null },
    DUALMODE_CHECKOUT_CONFIRMATION: { name: 'ecomm_completed', value: null },
}

function trackConvertGoal(id) {
    const urlParams = new URLSearchParams(window.location.search);
    const isPreviewURL = urlParams.has('convert_action') && urlParams.get('convert_action') === 'convert_vpreview';
    const isForcedVariationURL = urlParams.has('_conv_eforce');
    if (!isPreviewURL && !isForcedVariationURL && CONVERT_GOALS[id]) {
        window._conv_q = window._conv_q || [];
        window._conv_q.push(['triggerConversion', CONVERT_GOALS[id]]);
    }
}

function logStatsigEvent(id) {
    if (!window.Statsig) {
        return;
    }

    if (!STATSIG_GOALS[id]) {
        return;
    }

    const event = STATSIG_GOALS[id];

    window.Statsig.StatsigClient.instance().logEvent(event.name, event.value);
}

module.exports = {
    handleCheckoutSteps: function (checkoutStep) {
        if (!$('#checkout-main').data('mealplans')) {
            return;
        }

        const stepGoalMap = {
            0: 'MP_ACCOUT_CREATION',
            1: 'MP_CHECKOUT',
            2: 'MP_CHECKOUT_PAYMENT',
            4: 'MP_CHECKOUT_CONFIRMATION',
        };

        const goal = stepGoalMap[checkoutStep];
        if (goal) {
            trackConvertGoal(goal);
        }
    },
    handleMealplanSteps: function (mealplanStep) {
        if (!$('.meal-plans-content').length) {
            return;
        }

        const stepGoalMap = {
            characteristics: 'MP_INTRODUCTION',
            health: 'MP_HEALTH',
            nutrition: 'MP_NUTRITION_AND_ALLERGENS',
            box: 'MP_PLAN_SELECTION',
            configuration: 'MP_SUMMARY',
            login: 'MP_LOGIN',
        };

        const goal = stepGoalMap[mealplanStep];
        if (goal) {
            trackConvertGoal(goal);
        }
    },
    handleDualModeSteps: function (dualmodeStep) {
        if (cookieHelpers.getCookie('dualMode') !== 'true') {
            return;
        }

        const stepGoalMap = {
            cart: 'DUALMODE_CART',
            intentToProceed: 'DUALMODE_INTENT_PROCEED',
            1: 'DUALMODE_CHECKOUT',
            2: 'DUALMODE_CHECKOUT_PAYMENT',
            4: 'DUALMODE_CHECKOUT_CONFIRMATION',
        };

        const goal = stepGoalMap[dualmodeStep];
        if (goal) {
            trackConvertGoal(goal);
            logStatsigEvent(goal);
        }
    },
};
